import { GroupDetailsTab } from 'pages/Groups/Interfaces/Groups';

export const useRoutesGenerator = () => {
  let firstPath = window.location.pathname.split('/')[1];
  let initPath = '';
  if (firstPath === 't23') {
    firstPath = window.location.pathname.split('/')[2];
    initPath = '/t23';
  }
  initPath = initPath + (['admin', 'mentor', 'user', 'receptionist'].includes(firstPath) ? `/${firstPath}` : '');

  return {
    CLIENTS: {
      BASE: `${initPath}/client`,
      PROFILE: `${initPath}/client/:clientRecordId/profile`,
      CLIENT_DATA: `${initPath}/client/:clientRecordId/client-data`,
      HEALTH_DATA: `${initPath}/client/:clientRecordId/health-data`,
      ACTIVITY: `${initPath}/client/:clientRecordId/activity`,
      EPISODE: `${initPath}/client/:clientRecordId/episodes`,
      NOTES: `${initPath}/client/:clientRecordId/notes`,
      ATTACHMENTS: `${initPath}/client/:clientRecordId/attachments`,
      SURVEY: `${initPath}/client/:clientRecordId/survey`,
      ASSESSMENTS: `${initPath}/client/:clientRecordId/assessments`,
      CHECK_IN: `${initPath}/client/:clientRecordId/checkIn`,
      REPORT: `${initPath}/client/:clientRecordId/report`,
      LETTERS: `${initPath}/client/:clientRecordId/letters`,
      APPOINTMENTS: `${initPath}/client/:clientRecordId/appointments`,
      INVOICES: `${initPath}/client/:clientRecordId/invoices`,
      ASSIGNMENT: `${initPath}/client/:clientRecordId/assignment`,
      REFERRERS: `${initPath}/client/:clientRecordId/referrers`,
      MEDICARE: `${initPath}/client/:clientRecordId/medicare`
    },
    CARECOORDINATION: {
      GROUPS: `${initPath}/groups`,
      GROUP_PSYCHOMETRICS: `${initPath}/groups/:groupId/${GroupDetailsTab.Psychometrics}/:psychometricId/clinicalAssessmentId/:clinicalAssessmentId`,
      CAREPATHWAYS: `${initPath}/care-pathways`,
      SURVEY: {
        DETAIL: 'detail'
      }
    },
    PRACTITIONER: {
      BASE: `${initPath}/practitioners`,
      MANAGE: `${initPath}/practitioners/manage`
    },
    ASSESSMENT: {
      BASE: `${initPath}/assessments`
    },
    FORMS: {
      BASE: `${initPath}/forms`,
      LETTER_TEMPLATE: `${initPath}/forms/letters`,
      NEW_LETTER_TEMPLATE: `${initPath}/forms/letters/new`,
      REPORT_TEMPLATE: `${initPath}/forms/reports`,
      NEW_REPORT_TEMPLATE: `${initPath}/forms/reports/newTemplate`,
      INVOICE_TEMPLATE: `${initPath}/forms/invoices`,
      NEW_INVOICE_TEMPLATE_EDITOR: `${initPath}/forms/invoices/new`,
      NEW_CARE_PATHWAY_TEMPLATE: `${initPath}/forms/carepathway/new`,
      CARE_PATHWAY_TEMPLATE: `${initPath}/forms/carepathway`
    },
    MESSAGES: {
      BASE: `${initPath}/messages`,
      BROADCAST: `${initPath}/messages/broadcastMessage`
    },
    REPORTS: {
      BASE: `${initPath}/reports`,
      NEW: `${initPath}/reports/newReport`
    },
    LETTERS: {
      BASE: `${initPath}/letters`,
      NEW: `${initPath}/letters/new`
    },
    CALENDAR: {
      BASE: `${initPath}/calendar`,
      DAY: `${initPath}/calendar/view/day`,
      WEEK: `${initPath}/calendar/view/week`,
      APT_SETUP: `${initPath}/calendar/appointment/setup`,
      APT_SETUP_ACC_LEVEL: `${initPath}/calendar/appointment/setup/account-level-rules`,
      SETTINGS: `${initPath}/calendar/settings`,
      SETTINGS_APT_TYPE: `${initPath}/calendar/settings/appointmentTypes`,
      SETTINGS_CLD_LINKING: `${initPath}/calendar/settings/calendarLinking`,
      SETTINGS_VIEWS: `${initPath}/calendar/settings/calendarSavedViews`
    },
    CAREPLAN: {
      BASE: `${initPath}/careplan`,
      NEW: `${initPath}/careplan/new`
    },
    INVOICES: {
      BASE: `${initPath}/invoices`,
      NEW: `${initPath}/invoices/new`,

      PENDING_CLAIMS_DATA_READY: `${initPath}/invoices/pending-claims-data-ready`,
      PENDING_CLAIMS_DATA_NOT_READY: `${initPath}/invoices/pending-claims-data-not-ready`,
      PENDING_CLAIMS_ABANDONED: `${initPath}/invoices/pending-claims-abandoned`,

      SUBMITTED_CLAIMS: `${initPath}/invoices/submitted-claims`,
      SUCCESS_CLAIMS: `${initPath}/invoices/success-claims`,
      REJECTED_CLAIMS: `${initPath}/invoices/rejected-claims`,
      WRITE_OFF_CLAIMS: `${initPath}/invoices/write-off-claims`,
      ABANDONED_CLAIMS: `${initPath}/invoices/abandoned-claims`
    },
    CONTROL_PANEL: {
      BASE: `${initPath}/control-panel`,
      PRACTICE: `${initPath}/control-panel/practice`,
      ABOUT_YOU: `${initPath}/control-panel/about-you`,
      SYSTEM_CONNECTIONS: `${initPath}/control-panel/system-connections`,
      NOTIFICATION_SETTINGS: `${initPath}/control-panel/notification-settings`,
      SCHEDULE: `${initPath}/control-panel/schedule`,
      EFAX: `${initPath}/control-panel/system-connections/eFax`,
      SERVICES_REPORT: `${initPath}/control-panel/services-report`
    },
    MENTOR: {
      BASE: initPath,
      CLIENTS: `${initPath}/clients`,
      ABOUT_YOU: `${initPath}/about-you`,
      SCHEDULE: `${initPath}/schedule`
    },
    ANALYTICS: {
      BASE: `${initPath}/analytics`
    },
    HELM_CONTENT: {
      BASE: `${initPath}/helm-content`
    },
    ADDRESS_BOOK: {
      BASE: `${initPath}/address-book`
    },
    TASKS: {
      BASE: `${initPath}/tasks`
    },
    REFERRALS: {
      BASE: `${initPath}/referrals`
    }
  };
};
