import { createContext, ReactNode, useEffect } from 'react';
import Loading from '../components/Loading/Loading';
import NoAuthLayout from '../layouts/NoAuthLayout/NoAuthLayout';
import { ProfileInterface } from '../interfaces/Profile/Profile';
import { useTranslation } from 'react-i18next';
import { getTerminology } from 'i18n/resources/en/terminology';
import { useAppSelector } from '../redux/hooks';
import { myProfileInfo } from '../redux/slice/profileDataSlice';

export enum CustomFeature {
  Mentor = 'mentor',
  Vadc = 'vadc',
  HelmContent = 'helmContent',
  Helm = 'helm',
  MedicareRebate = 'medicareRebate',
  MentalHealthHubLogo = 'mentalHealthHubLogo',
  RequestApprovalByDefault = 'requestApprovalByDefault',
  TemplateName = 'templateName',
  Fax = 'fax',
  SomeoneHealth = 'someoneHealth',
  CaW = 'caw',
  CarePlan = 'carePlan',
  LetterDisabled = 'letterDisabled',
  EaseWellbeing = 'easeWellbeing',
  EoC = 'eoc',
  MultiTimeZone = 'multiTimeZone',
  EngagePublicProfile = 'engagePublicProfile',
  InvoiceTemplate = 'invoiceTemplate',
  RechargeWellness = 'rechargeWellness',
  ClientProfileEpisode = 'clientProfileEpisode',
  AppointmentActions = 'appointmentActions',
  Egrist = 'egrist',
  HealthData = 'healthData',
  HidePrivateCaseNotes = 'hidePrivateCaseNotes',
  Packages = 'packages',
  SelectPsychology = 'selectPsychology',
  ProcessAppointment = 'processAppointment',
  AddressBook = 'addressBook',
  InvoiceListV2 = 'invoiceListV2',
  TwilioTelehealth = 'twilioTelehealth',
  ReferralV2 = 'referralV2',
  AICompanion = 'aiCompanion'
}

const initialValues = {
  customFeatures: [],
  accountSettings: {},
  profileSettings: {},
  clinicianProfile: undefined
};

export const UserContext = createContext<{
  customFeatures: CustomFeature[];
  accountSettings: ProfileInterface['accountSettings'];
  profileSettings: ProfileInterface['profileSettings'];
  clinicianProfile?: ProfileInterface;
}>(initialValues);

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const { data: profile, isLoading: isProfileLoading } = useAppSelector(myProfileInfo);
  const [, trans] = useTranslation();

  useEffect(() => {
    if (profile && profile.accountSettings) {
      trans.addResourceBundle('en', 'terminology', getTerminology(profile.accountSettings), true, true);
    }
  }, [profile, trans]);

  return isProfileLoading ? (
    <NoAuthLayout>
      <Loading />
    </NoAuthLayout>
  ) : (
    <UserContext.Provider
      value={{
        customFeatures: profile.customFeatures || [],
        accountSettings: profile.accountSettings,
        profileSettings: profile.profileSettings,
        clinicianProfile: profile
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
